import { Link } from "gatsby";
import React from "react";

const NavLinkClasses = "db f4 pv3 mv0 no-underline";

export const Header = () => (
  <header
    role="banner"
    className="c-f-sans flex flex-row justify-between mw8 center items-baseline content-stretch ph2 ph4-ns"
  >
    <Link className="red f3 pv4 mv0 no-underline" to="/" aria-hidden="true">
      Liam Dawson
    </Link>
    <div className="flex flex-row">
      <Link to="/" className={NavLinkClasses}>
        Home
      </Link>
    </div>
  </header>
);

export default Header;
