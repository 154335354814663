import { graphql, Link } from "gatsby";
import React from "react";
import { nodes } from "../utils";
import cx from "classnames";
import Img from "gatsby-image";

import { ConstrainedLayout } from "../components/layouts";
import SEO from "../components/seo";

const SocialProfile = ({ name, username, href, preferred }) => (
  <li className="pb2">
    <a className="no-underline" href={href}>
      <span className={cx("underline", { fw6: preferred })}>
        {name}
        {preferred && <> (preferred)</>}
      </span>
      <br />
      <small aria-hidden="true">{username ? " " + username : ""}</small>
    </a>
  </li>
);

const ProjectProfile = ({ name, description, href, currentStatus }) => (
  <li className="pb2">
    <a href={href}>{name}</a> ({currentStatus})<p>{description}</p>
  </li>
);

const IndexPage = ({ data }: { data: IPageData }) => (
  <ConstrainedLayout>
    <SEO
      title="Home"
      lang="en"
      keywords={["Liam Dawson", "Melbourne", "developer", "software engineer"]}
    />

    <section className="db bg-near-white pa4 mb3 near-black">
      <div className="flex flex-column flex-row-ns">
        <Img
          className="db flex-grow-1 mr2"
          fluid={data.myAvatar.childImageSharp.fluid}
          alt="Photo of Liam Dawson"
        />
        <header className="flex-grow-1 ml2">
          <h1 className="c-f-sans">Liam Dawson</h1>
          <h2 className="c-f-sans f4">
            Level {new Date().getYear() - 94}-{new Date().getYear() - 93}{" "}
            Software Developer
          </h2>
          <p>Works with almost anything, including (and especially) people.</p>
        </header>
      </div>
    </section>

    <section className="db bg-near-white pa4 mb3 near-black">
      <header className="c-f-sans">
        <h2 className="ma0 mb2">Working on:</h2>
      </header>

      <ul className="lh-copy pl3">
        {nodes(data.allProjectsJson).map(source => (
          <ProjectProfile key={source.id} {...source} />
        ))}
      </ul>
    </section>

    <section className="db bg-near-white pa4 mb3 near-black">
      <header className="c-f-sans">
        <h2 className="ma0 mb2">Elsewhere</h2>
      </header>

      <ul className="lh-copy pl3">
        {nodes(data.allSocialProfilesJson).map(source => (
          <SocialProfile key={source.id} {...source} />
        ))}
      </ul>
    </section>
  </ConstrainedLayout>
);

interface IPageData {
  allSocialProfilesJson: {
    edges: [
      {
        node: {
          id: string;
          name: string;
          username: string;
          href: string;
          preferred: boolean;
        };
      }
    ];
  };
  allProjectsJson: {
    edges: [
      {
        node: {
          id: string;
          name: string;
          description: string;
          href: string;
          currentStatus: string;
        };
      }
    ];
  };
  myAvatar: {
    childImageSharp: {
      fluid: any;
    };
  };
}

export const pageQuery = graphql`
  {
    allSocialProfilesJson(sort: { fields: preferred }) {
      edges {
        node {
          id
          name
          username
          href
          preferred
        }
      }
    }
    allProjectsJson {
      edges {
        node {
          id
          name
          description
          href
          currentStatus
        }
      }
    }
    myAvatar: file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
