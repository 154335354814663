import React from "react";
import DefaultLayout from "./default";

export const ConstrainedLayout = ({ children }) => (
  <DefaultLayout>
    <main className="mw8 center">{children}</main>
  </DefaultLayout>
);

export default ConstrainedLayout;
