import React from "react";
import Header from "../header";

export const DefaultLayout = ({ children }) => (
  <>
    <Header />
    {children}
  </>
);

export default DefaultLayout;
